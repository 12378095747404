<script>
import liffapi from '@/liffapi'
import utils from '@/utils.js'

export default {
  name: 'LoginIndex',

  props: {
    redirectUrl: {
      type: String,
      default: null,
    },
    application: {
      type: String,
      default: null,
    },
  },

  emits: ['openscan'],

  data() {
    return {
      user: null,
      isLoading: false,
    }
  },

  computed: {},

  mounted() {},

  methods: {
    openScan() {
      this.$emit('openscan')
    },
  },
}
</script>

<template>
  <div class="container has-text-centered">
    <div class="firebaseui-auth-container">
      <div
        class="firebaseui-card-header"
        style="background: #012d59; margin: 0 auto; max-width: 362px">
        <img src="@/assets/Liff-marvin-white.png" style="height: 4rem" />
      </div>

      <p class="mb-3">Login Methods</p>

      <div class="pl-6 has-text-left" style="min-height: 12rem">
        <o-button
          data-test="signInEmailBtn"
          tag="router-link"
          :to="{ path: '/signin', query: { r: redirectUrl, a: application } }"
          icon-left="email"
          class="mb-3">
          Sign in with email
        </o-button>
        <br />

        <div>
          <o-button
            data-test="quickLoginBtn"
            class="mr-4"
            icon-left="qrcode"
            @click="openScan">
            Quick Login
          </o-button>
          <o-icon icon="information" variant="info" style="height: 100%" />
        </div>
        <p class="is-size-7 pl-3">Using another device</p>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<style lang="scss"></style>
