<script>
export default {
  name: 'LoginApp',

  props: {
    path: {
      type: String,
      default: null,
    },
  },

  mounted() {
    this.$router.push({
      name: 'LoginIndexRedirect',
      params: { redirectUrl: this.path },
    })
  },
}
</script>

<template>
  <div></div>
</template>

<style scoped></style>
