import { createRouter, createWebHashHistory } from 'vue-router'

import ForgotPassword from './ForgotPassword.vue'
import LoginIndex from './LoginIndex.vue'
import SignIn from './SignIn.vue'
import ReDirect from './Redirect.vue'

const routes = [
  {
    path: '/',
    name: 'LoginIndex',
    component: LoginIndex,
  },
  {
    path: '/r/:redirectUrl',
    name: 'LoginIndexRedirect',
    component: LoginIndex,
    props: true,
  },
  {
    path: '/r/:redirectUrl/a/:application',
    name: 'LoginIndexAppRedirect',
    component: LoginIndex,
    props: true,
  },
  {
    path: '/trouble',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/:path(.*)',
    name: 'redirect',
    component: ReDirect,
    props: true,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
})

export default router
